<template>
  <div>
    <Header />
    <div class="page-title">
      <div class="container">
        <div class="title text-black">Política de Cookies Tradecorp</div>
      </div>
    </div>

    <section>
      <div class="container mt-2">
        <div class="sub-title">
          Compromisso de Proteção de Dados e de Privacidade
        </div>
        <p class="text-desc mt-1">
          A Política de Cookies regula o tratamento de dados, incluindo dados
          pessoais, dos Usuários (“Utilizador” ou “Usuários”), coletados no
          âmbito da utilização dos sites eletrônicos ou aplicações através de
          Cookies, pela TRADECORP DO BRASIL COMERCIO DE INSUMOS AGRICOLAS LTDA,
          CNPJ: 04.997.059/0001-57, enquanto entidade controladora, designada
          doravante como "EMPRESA".
        </p>
        <p class="text-desc mt-1">
          A presente Política de Cookies informa quais são os tipos de Cookies
          utilizados pela EMPRESA no site
          <router-link class="link" target="_blank" to="/home">
            https://tradecorp.com.br </router-link
          >, ficando excluídos outros sites que podem ter Políticas de Cookies
          específicas.
        </p>
        <p class="text-desc mt-1">
          É possível escolher os tipos de Cookies que podem ser descarregados
          para o seu dispositivo de acesso à internet (computador, celular,
          tablet, etc.) através das definições de privacidade nas opções do
          navegador de internet utilizado (doravante designado por "browser").
        </p>
      </div>

      <div class="container mt-2">
        <div class="sub-title">
          Política de Cookies e Política de Proteção de Dados
        </div>
        <p class="text-desc mt-1">
          Esta Política de Cookies deve ser lida pelo Usuário em conjugação com
          a Política de Proteção de Dados Pessoais, acessível ao Usuário através
          do link
          <router-link
            class="link"
            target="_blank"
            to="/politica-de-privacidade"
            >https://tradecorp.com.br/politica-de-privacidade</router-link
          >.
        </p>
      </div>

      <div class="container mt-2">
        <div class="sub-title">Pontos de Contato</div>
        <p class="text-desc mt-1">
          Para quaisquer questões sobre a Política de Cookies, poderá contactar
          a EMPRESA através dos seguintes pontos de contato: <br />
          - Endereço de Correio Eletrônico:
          <a class="link" href="mailto:dpo.brasil@rovensa.com"
            >dpo.brasil@rovensa.com</a
          >;
          <br />
          - Endereço Postal: Rua Senador Feijó, n° 131, Conj. 111, Sé, São
          Paulo, SP, Brasil — CEP: 01.006-001
        </p>
      </div>

      <div class="container mt-2">
        <div class="sub-title">Noção de Cookies</div>
        <p class="text-desc mt-1">
          Os Cookies são pequenos arquivos de informação colocados pelos sites
          eletrônicos que o Usuário visita e que são armazenados no sistema de
          navegação (browser) do dispositivo utilizado para acesso à internet
          (por exemplo, o computador ou celular). Estes arquivos ajudam os sites
          eletrônicos a memorizar informações sobre a visita e a facilitar a
          próxima visita, mantendo as ações e preferências dos Usuários durante
          um certo período de tempo, mantendo ou reconhecendo o nome de Usuário,
          o idioma em que pretende navegar nos sites ou outras definições, bem
          como personalizando a experiência do Usuário através de conteúdos
          específicos ou, ainda, permitindo a coleta de informação sobre a
          utilização dos sites eletrônicos.
        </p>
      </div>

      <div class="container mt-2">
        <div class="sub-title">Categorizações de Cookies</div>
        <p class="text-desc mt-1">
          Os Cookies podem ser classificados tendo em conta três grandes
          categorizações, consoante: <br />
          - a sua finalidade; <br />
          - a entidade colocadora; e
          <br />
          - o período de vida ou de conservação.
        </p>
      </div>

      <div class="container mt-2">
        <div class="sub-title">
          Categorização dos Cookies quanto à Finalidade
        </div>
        <p class="text-desc mt-1">
          Considerando as suas finalidades, os Cookies são classificados da
          seguinte forma:
        </p>
        <p class="text-desc mt-1">
          (a) <b>Cookies necessários</b> – têm um caráter funcional e são
          necessários para assegurar o funcionamento do site e/ou para assegurar
          a disponibilização de funcionalidades que sejam solicitadas pelo
          Usuário. bem como ainda para guardar as suas preferências
          relativamente à navegação no mesmo, sempre que utilizar o mesmo
          dispositivo e assim o solicite.
        </p>
        <p class="text-desc mt-1">
          (b) <b>Cookies analíticos</b> – têm caráter acessório e coletam
          informação estatística para efeitos de análise da sua utilização dos
          mesmos e gestão do relacionamento com os visitantes, medindo as
          interações com os anúncios apresentados.
        </p>
        <p class="text-desc mt-1">
          (c) <b>Cookies de personalização</b> – têm caráter acessório, não
          sendo necessários para o funcionamento do website e melhorando o seu
          desempenho, permitem uma personalização da experiência da navegação do
          utilizador.
        </p>
        <p class="text-desc mt-1">
          (d) <b>Cookies de publicidade</b> – têm carácter acessório, procurando
          tornar a publicidade mais atrativa para usuários e anunciantes,
          através da seleção de publicidade com base nas escolhas do Usuário, de
          forma a melhorar os relatórios sobre o desempenho de campanhas e
          potencializar a apresentação de anúncios que o Usuário já tenha visto
          ou assinalado como relevantes.
        </p>
      </div>

      <div class="container mt-2">
        <div class="sub-title">
          Categorização dos Cookies quanto à Entidade Colocadora
        </div>
        <p class="text-desc mt-1">
          Considerando a entidade que é responsável pela colocação dos Cookies,
          estes são classificados da seguinte forma:
        </p>
        <p class="text-desc mt-1">
          (a) <b>Cookies próprios</b> – Cookies que são enviados para o
          dispositivo do Usuário através de equipamentos ou domínios geridos
          pela EMPRESA e a partir do qual se presta o serviço solicitado pelo
          Usuário.
        </p>
        <p class="text-desc mt-1">
          (b) <b>Cookies de terceiros</b> – são enviados para o dispositivo do
          Usuário a partir de um equipamento ou domínio gerido por uma entidade
          terceira sob a qual a EMPRESA não tem controle, situação em que a
          entidade terceira pode eventualmente tratar os dados coletados sem que
          a EMPRESA tenha capacidade de configurar o seu funcionamento.
        </p>
      </div>

      <div class="container mt-2">
        <div class="sub-title">
          Categorização dos Cookies quanto ao Período de Vida Útil
        </div>
        <p class="text-desc mt-1">
          Considerando o período de vida útil dos Cookies, estes são
          classificados da seguinte forma:
        </p>
        <p class="text-desc mt-1">
          (a) <b>Cookies de 'sessão'</b> – Cookies temporários que são
          normalmente apagados quando o Usuário abandona a página eletrônica que
          as gerou.
        </p>
        <p class="text-desc mt-1">
          (b) <b>Cookies 'resistentes'</b> – são Cookies que permanecem no
          dispositivo do Usuário até uma determinada data, ao nível dos
          browsers, sendo utilizados sempre que faz uma nova visita ao site.
        </p>
      </div>

      <div class="container mt-2">
        <div class="sub-title">Lista de Cookies – Apresentação</div>
        <p class="text-desc mt-1">
          Neste site eletrônico são utilizados os seguintes tipos de Cookies:
          <br />
          a) Cookies estritamente necessários - Sim; <br />
          b) Cookies analíticos - Não; <br />
          c) Cookies de personalização - Não; <br />
          d) Cookies de publicidade - Não.
        </p>
      </div>

      <div class="container mt-2">
        <div class="sub-title">Lista de Cookies – Descritivo</div>
        <p class="text-desc mt-1">
          A informação pormenorizada relativamente às funcionalidades e
          utilização destes Cookies é a seguinte:
        </p>
        <div class="table-holder mt-1 mobile12">
          <table class="mobile12">
            <thead>
              <tr>
                <th>NOME</th>
                <th>TIPO</th>
                <th>FINALIDADE</th>
                <th>TEMPO DE DURAÇÃO</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in table" :key="index">
                <td>{{ item.name }}</td>
                <td>{{ item.type }}</td>
                <td>{{ item.finality }}</td>
                <td>{{ item.duration }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="container mt-2">
        <div class="sub-title">Aceitação da Utilização de Cookies</div>
        <p class="text-desc mt-1">
          Não é possível aos sites funcionar sem os Cookies funcionais
          estritamente necessários, pelo que o Usuário está obrigado a aceitar a
          respectiva utilização. Quanto aos outros tipos de Cookies, o Usuário é
          livre para consentir na sua utilização.
        </p>
        <p class="text-desc mt-1">
          Solicitamos sempre o consentimento prévio e expresso do Usuário,
          exceto se a colocação de determinados Cookies for estritamente
          necessária para prestar o serviço que tenha expressamente solicitado
          ou para disponibilizar o acesso a uma determinada funcionalidade desse
          serviço, tais como o acesso a áreas reservadas, o acesso a
          funcionalidades ou páginas específicas, etc.
        </p>
      </div>

      <div class="container mt-2">
        <div class="sub-title">Configuração dos Browsers</div>
        <p class="text-desc mt-1">
          Os browsers permitem um controle individualizado dos Cookies
          armazenados no seu dispositivo, bem como a sua eliminação imediata,
          caso o usuário pretenda deixar de permitir o armazenamento local de
          Cookies.
        </p>
        <p class="text-desc mt-1">
          Qualquer Usuário pode alterar as configurações do seu browser para
          bloquear a instalação de Cookies ou para o notificar cada vez que um
          Cookie é definido.
        </p>
        <p class="text-desc mt-1">
          Para mais informações sobre como realizar uma “Navegação Privada ou
          Sem Registo de Dados” e proceder a uma gestão eficiente dos Cookies
          consulte, por favor:
        </p>
        <p class="text-desc mt-1">
          - <b>Firefox</b>, clique aqui; <br />
          <a
            class="link"
            target="_blank"
            rel="noreferrer"
            href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-
          disable-cookies-website-preferences&redirectlocale=en-US"
            >https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-
            disable-cookies-website-preferences&redirectlocale=en-US</a
          >
        </p>
        <p class="text-desc mt-1">
          - <b>Chrome</b>, clique aqui; <br />
          <a
            class="link"
            target="_blank"
            rel="noreferrer"
            href="https://support.google.com/chrome/answer/95647?hl=en?hl=en"
            >https://support.google.com/chrome/answer/95647?hl=en?hl=en</a
          >
        </p>
        <p class="text-desc mt-1">
          - <b>Internet Explorer</b>, clique aqui; <br />
          <a
            class="link"
            target="_blank"
            rel="noreferrer"
            href="https://support.microsoft.com/pt-pt/windows/eliminar-e-gerir-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
            >https://support.microsoft.com/pt-pt/windows/eliminar-e-gerir-cookies-168dab11-0753-043d-7c16-ede5947fc64d</a
          >
        </p>
        <p class="text-desc mt-1">
          - <b>Microsoft Edge</b>, clique aqui; <br />
          <a
            class="link"
            target="_blank"
            rel="noreferrer"
            href="https://support.microsoft.com/pt-pt/windows/eliminar-e-gerir-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
            >https://support.microsoft.com/pt-pt/windows/eliminar-e-gerir-cookies-168dab11-0753-043d-7c16-ede5947fc64d</a
          >
        </p>
        <p class="text-desc mt-1">
          - <b>Safari</b>, clique aqui; <br />
          <a
            class="link"
            target="_blank"
            rel="noreferrer"
            href="https://support.apple.com/en-us/HT201265"
            >https://support.apple.com/en-us/HT201265</a
          >
        </p>
        <p class="text-desc mt-1">
          - <b>Opera</b>, clique aqui; <br />
          <a
            class="link"
            target="_blank"
            rel="noreferrer"
            href="https://help.opera.com/en/latest/security-and-privacy/#privateWindow"
            >https://help.opera.com/en/latest/security-and-privacy/#privateWindow</a
          >
        </p>
        <p class="text-desc mt-1">
          Deve estar consciente das consequências da desativação dos Cookies,
          uma vez que tal operação pode ter um impacto no funcionamento dos
          respectivos sites, pois o painel de configurações da generalidade dos
          browsers modernos não oferece um nível de detalhe que permita aos
          Usuários afastar apenas todos os Cookies que não sejam estritamente
          necessários para permitir a utilização dos sites visitados.
        </p>
      </div>

      <div class="container mt-2">
        <div class="sub-title">Aplicações de Privacidade</div>
        <p class="text-desc mt-1">
          Nos sites, dispositivos ou equipamentos que ofereçam este tipo de
          funcionalidades, o Usuário poderá ter maior controle sobre os seus
          Cookies com recurso a aplicações gratuitas de proteção da privacidade
          que se encontram disponíveis em várias plataformas online — alguns
          exemplos de aplicações deste gênero, são, entre outras, as seguintes:
          <br />
          - Ghostery; <br />
          - Privacy Possum; <br />
          - Cookie AutoDelete; <br />
          - etc.
        </p>
      </div>

      <div class="container mt-2">
        <div class="sub-title">Alterações à Política de Cookies</div>
        <p class="text-desc mt-1">
          A EMPRESA reserva o direito de, livremente e em qualquer momento, sem
          aviso prévio e com efeitos imediatos, alterar, acrescentar ou revogar,
          parcial ou totalmente, esta Política de Cookies.
        </p>
        <p class="text-desc mt-1">
          Todas as alterações serão imediatamente divulgadas nos Canais de
          Comunicação da organização, podendo os Usuários ter acesso à
          informação e, consoante o concreto tipo de cookie em causa, consentir,
          de uma forma informada, na sua utilização, ou, se for o caso,
          procedendo à retirada de consentimento previamente concedido, através
          da configuração dos browsers.
        </p>
      </div>

      <div class="container mt-2">
        <div class="sub-title">Exercício de Direitos</div>
        <p class="text-desc mt-1">
          Os Usuários podem, enquanto titulares de dados pessoais, em qualquer
          momento, proceder ao exercício dos seus direitos de proteção de dados
          e de privacidade, designadamente dos direitos de retirada do
          consentimento, acesso, retificação, apagamento, portabilidade,
          limitação ou oposição ao tratamento, nos termos e com as limitações
          previstas nas normas aplicáveis.
        </p>
        <p class="text-desc mt-1">
          Qualquer pedido de exercício de direitos de proteção de dados e de
          privacidade deve ser dirigido, por escrito, pelo respectivo titular,
          para o endereço de correio eletrônico do Encarregado da Proteção de
          Dados da EMPRESA abaixo indicado, ou ser apresentado através do
          Formulário para Exercício de Direitos acessível em qualquer ponto de
          atendimento.
        </p>
      </div>

      <div class="container mt-2">
        <div class="sub-title">Comunicação de Incidentes</div>
        <p class="text-desc mt-1">
          A EMPRESA tem implementado um sistema de gestão de incidentes no
          âmbito da proteção de dados e segurança da informação.
        </p>
        <p class="text-desc mt-1">
          Caso algum Usuário pretenda comunicar a ocorrência de uma qualquer
          situação de violação de dados pessoais, que provoque, de modo
          acidental ou ilícito, a destruição, a perda, a alteração, a divulgação
          ou o acesso, não autorizados, a dados pessoais transmitidos,
          conservados ou sujeitos a qualquer outro tipo de tratamento, pode
          entrar em contacto com o Encarregado da Proteção de Dados ou utilizar
          os contatos gerais da EMPRESA.
        </p>
        <p class="text-desc mt-1">
          O Formulário para Comunicação de Incidentes de Violação de Dados da
          EMPRESA está acessível em qualquer ponto de atendimento.
        </p>
      </div>

      <div class="container mt-2">
        <div class="sub-title">Sugestões, Reclamações ou Queixas</div>
        <p class="text-desc mt-1">
          Os Usuários podem apresentar sugestões sobre medidas técnicas ou
          organizativas que considerem mais adequadas para melhoria das
          condições de proteção de dados e privacidade, através de e-mail
          enviado para o Encarregado da Proteção de Dados da EMPRESA através do
          e-mail:
          <a class="link" href="mailto:dpo.brasil@rovensa.com"
            >dpo.brasil@rovensa.com</a
          >
        </p>
        <p class="text-desc mt-1">
          Os Usuários têm ainda o direito de apresentar reclamação, quer através
          do registo da reclamação no registro interno de Solicitações do
          Titular, quer através da apresentação de reclamação às autoridades de
          controle – podendo, neste último caso, apresentar petição ou queixa
          diretamente à ANPD — Autoridade Nacional de Proteção de Dados através
          dos contatos disponíveis em
          <a
            class="link"
            target="_blank"
            rel="noreferrer"
            href="https://www.gov.br/anpd/pt-br"
            >https://www.gov.br/anpd/pt-br</a
          >.
        </p>
      </div>

      <div class="container mt-2">
        <div class="sub-title">Encarregado da Proteção de Dados</div>
        <p class="text-desc mt-1">
          A EMPRESA designou um Encarregado da Proteção de Dados, cujos meios de
          contato são os seguintes: <br />
          - Endereço de correio eletrónico:
          <a class="link" href="mailto:dpo.brasil@rovensa.com"
            >dpo.brasil@rovensa.com</a
          >; <br />
          - Endereço postal: Rua Senador Feijó, n° 131, Conj. 111, Sé, São
          Paulo, SP, Brasil — CEP: 01.006-001.
        </p>
      </div>

      <div class="container mt-2">
        <div class="sub-title">
          Registro de Operações de Tratamento de Dados
        </div>
        <p class="text-desc mt-1">
          Para consultar o Registro de Operações de Tratamento de Dados de
          Usuários pelos Cookies é favor contactar o Encarregado da Proteção de
          Dados.
        </p>
      </div>

      <div class="container mt-2">
        <div class="sub-title">Versão e Data da Política de Cookies</div>
        <p class="text-desc mt-1">Data: 18/05/2022;</p>
        <p class="text-desc mt-1">Versão: 1.0;</p>
        <p class="text-desc mt-1">
          Para consultar versões anteriores da Política de Cookies, favor
          contactar o Encarregado da Proteção de Dados.
        </p>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
export default {
  name: "TermsCookies",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      table: [
        {
          name: "_gid",
          type: "Analítico",
          finality:
            "Cookie para análise da experiência do usuário em nossa plataforma utilizando IDs anônimos.",
          duration: "1 dia",
        },
        {
          name: "_ga",
          type: "Analítico",
          finality:
            "Cookie utilizado para análise da experiência do usuário em nossa plataforma utilizando IDs anônimos.",
          duration: "2 anos",
        },
        {
          name: "SID",
          type: "Promocional",
          finality:
            "Cookies para uso de entrega de mídia customizada para o usuário. Mais informações sobre o uso de cookies pelo Google podem ser encontradas em: 1. How Google Use Cookies: https://policies.google.com/technologies/cookies/; 2. Types of Cookies Uses By Google: https://policies.google.com/technologies/types/.",
          duration: "Sessão",
        },
        {
          name: "SIDCC",
          type: "Promocional",
          finality:
            "Cookies para uso de entrega de mídia customizada para o usuário. Mais informações sobre o uso de cookies pelo Google podem ser encontradas em: 1. How Google Use Cookies: https://policies.google.com/technologies/cookies/; 2. Types of Cookies Uses By Google: https://policies.google.com/technologies/types/.",
          duration: "Sessão",
        },
        {
          name: "_Secure-1PAPISID",
          type: "Promocional",
          finality:
            "Cookies para uso de entrega de mídia customizada para o usuário. Mais informações sobre o uso de cookies pelo Google podem ser encontradas em: 1. How Google Use Cookies: https://policies.google.com/technologies/cookies/; 2. Types of Cookies Uses By Google: https://policies.google.com/technologies/types/.",
          duration: "2 anos",
        },
        {
          name: "GRECAPTCHA",
          type: '"Required"',
          finality:
            "Habilita o Google ReCaptcha, usado para manter a segurança do site e evitar abuso.",
          duration: "Sessão",
        },
        {
          name: "SAPISID",
          type: "Promocional",
          finality:
            "Cookies para uso de entrega de mídia customizada para o usuário. Mais informações sobre o uso de cookies pelo Google podem ser encontradas em: 1. How Google Use Cookies: https://policies.google.com/technologies/cookies/; 2. Types of Cookies Uses By Google: https://policies.google.com/technologies/types/.",
          duration: "Sessão",
        },
        {
          name: "APISID",
          type: "Promocional",
          finality:
            "Cookies para uso de entrega de mídia customizada para o usuário. Mais informações sobre o uso de cookies pelo Google podem ser encontradas em: 1. How Google Use Cookies: https://policies.google.com/technologies/cookies/; 2. Types of Cookies Uses By Google: https://policies.google.com/technologies/types/.",
          duration: "Sessão",
        },
        {
          name: "SSID",
          type: "Promocional",
          finality:
            "Cookies para uso de entrega de mídia customizada para o usuário. Mais informações sobre o uso de cookies pelo Google podem ser encontradas em: 1. How Google Use Cookies: https://policies.google.com/technologies/cookies/; 2. Types of Cookies Uses By Google: https://policies.google.com/technologies/types/.",
          duration: "Sessão",
        },
        {
          name: "HSID",
          type: "Promocional",
          finality:
            "Cookies para uso de entrega de mídia customizada para o usuário. Mais informações sobre o uso de cookies pelo Google podem ser encontradas em: 1. How Google Use Cookies: https://policies.google.com/technologies/cookies/; 2. Types of Cookies Uses By Google: https://policies.google.com/technologies/types/.",
          duration: "Sessão",
        },
        {
          name: "1P_JAR",
          type: "Promocional",
          finality:
            "Cookies para uso de entrega de mídia customizada para o usuário. Mais informações sobre o uso de cookies pelo Google podem ser encontradas em: 1. How Google Use Cookies: https://policies.google.com/technologies/cookies/; 2. Types of Cookies Uses By Google: https://policies.google.com/technologies/types/.",
          duration: "Sessão",
        },
        {
          name: "NID",
          type: "Funcional",
          finality:
            "Utilizado para lembrar preferências e outras informações, como o idioma preferido e o número de resultados por página.",
          duration: "Sessão",
        },
        {
          name: "SEARCH_SAMESITE",
          type: "Promocional",
          finality:
            "Cookies para uso de entrega de mídia customizada para o usuário. Mais informações sobre o uso de cookies pelo Google podem ser encontradas em: 1. How Google Use Cookies: https://policies.google.com/technologies/cookies/; 2. Types of Cookies Uses By Google: https://policies.google.com/technologies/types/.",
          duration: "Sessão",
        },
        {
          name: "DV",
          type: "Analítico",
          finality:
            "Cookies utilizados para avaliação de comportamento e usabilidade das plataformas digitais. Mais informações sobre o uso de cookies pelo Google podem ser encontradas em: 1. How Google Use Cookies: https://policies.google.com/technologies/cookies/; 2. Types of Cookies Uses By Google: https://policies.google.com/technologies/types/.",
          duration: "Sessão",
        },
        {
          name: "__Secure-3PSIDCC",
          type: "Publicidade",
          finality:
            "Construir um perfil dos interesses do visitante, para então mostrar publicidade relevante e personalizada do Google.",
          duration: "2 anos",
        },
        {
          name: "_Secure-3PSID",
          type: "Publicidade",
          finality:
            "Construir um perfil dos interesses do visitante, para então mostrar publicidade relevante e personalizada do Google.",
          duration: "2 anos",
        },
        {
          name: "_Secure-1PSID",
          type: "Publicidade",
          finality:
            "Construir um perfil dos interesses do visitante, para então mostrar publicidade relevante e personalizada do Google.",
          duration: "2 anos",
        },
        {
          name: "_Secure-3PAPISID",
          type: "Publicidade",
          finality:
            "Construir um perfil dos interesses do visitante, para então mostrar publicidade relevante e personalizada do Google.",
          duration: "2 anos",
        },
        {
          name: "OTZ",
          type: "Analítico",
          finality: "Informações de tráfego, voltado para o Google Analytics.",
          duration: "1 mês",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.sub-title {
  position: relative;
  font-family: fontRegular;
  font-size: 2em;
  font-weight: lighter;
  color: #000;
}

.sub-title-2 {
  position: relative;
  font-family: fontRegular;
  font-size: 1.2em;
  font-weight: lighter;
  color: #000;
  margin: 15px 0 10px 0;
}

.link {
  color: blue;
  font-family: fontLight;
  font-size: 0.95em;
}

.table-holder {
  position: relative;
  max-width: 100%;
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 0.4em;
    height: 0.4em;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #111;
    outline: 1px solid slategrey;
  }
  @media only screen and (min-width: 1160px) {
    overflow-x: initial;
  }
}

table {
  position: relative;
  width: 80%;
  border-collapse: collapse;
  tr {
    position: relative;
    width: 100%;
    font-family: fontRegular;
    font-size: 1.1em;
    line-height: 1.5em;
  }
  th {
    text-align: center;
    height: 40px;
    border: 1px solid #000;
    padding: 8px 10px 8px 10px;
  }
  td {
    padding: 8px 10px 8px 10px;
    border: 1px solid #000;
    text-align: left;
  }
}
</style>
